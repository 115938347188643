import { Typography } from "@ory/elements";
import { PropsWithChildren } from "react";
import "./Panel.css";

const Panel = ({ children }: PropsWithChildren) => {
  return <div className="panel">{children}</div>;
};

type Props = PropsWithChildren<{
  title?: string;
  description?: string;
}>;

const Content = ({ title, description, children }: Props) => {
  return (
    <div className="panelContent">
      <div>
        <Typography size="headline21">{title}</Typography>
        {description && <Typography size="small">{description}</Typography>}
      </div>
      {children}
    </div>
  );
};

Panel.Content = Content;

const Footer = ({ children }: PropsWithChildren) => {
  return <div className="panelFooter">{children}</div>;
};

Panel.Footer = Footer;

export default Panel;
