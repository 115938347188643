import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import {
  ButtonLink,
  Card,
  CodeBox,
  Message,
  colorSprinkle,
  gridStyle,
  typographyStyle,
} from "@ory/elements";
import WarningSvg from "./assets/warning.svg";
import { FormattedMessage } from "react-intl";

const contactSupportEmail = "development@hgv.it";

export const Error = () => {
  const [error, setError] = useState<string>();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const queryError = searchParams.get("error");

    if (queryError !== null) {
      try {
        setError(
          JSON.stringify(JSON.parse(decodeURIComponent(queryError)), null, 2),
        );
      } catch {
        setError(queryError);
      }
    }
  }, []);

  return (
    <Card
      className="userAuthCard warningSvg"
      heading={
        <h2 className={typographyStyle({ type: "regular", size: "small" })}>
          <FormattedMessage id="error.title" />
        </h2>
      }
      image={WarningSvg}
      size="wide"
    >
      <div className={gridStyle({ gap: 32, direction: "column" })}>
        {error && (
          <CodeBox data-testid="code-box" toggleText="Fehlerdetails">
            {JSON.stringify(error, null, 2)}
          </CodeBox>
        )}
        <Message className={colorSprinkle({ color: "foregroundMuted" })}>
          <FormattedMessage
            id="error.support-email-link"
            description="A label and link below the error. The link href is 'mailto:{contactSupportEmail}'."
            defaultMessage="If the problem persists, please contact <a>{contactSupportEmail}</a>"
            values={{
              contactSupportEmail,
              a: (chunks) => (
                <ButtonLink href={`mailto:${contactSupportEmail}`}>
                  &nbsp;{chunks}
                </ButtonLink>
              ),
            }}
          />
        </Message>
        <Message>
          <ButtonLink href="/">
            <FormattedMessage id="error.back-button" defaultMessage="Go Back" />
          </ButtonLink>
        </Message>
      </div>
    </Card>
  );
};
